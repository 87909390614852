import React from "react"

import { StaticImage } from "gatsby-plugin-image"

import Stack from "@kiwicom/orbit-components/lib/Stack"
import Text from "@kiwicom/orbit-components/lib/Text"

import Container from "components/container"
import { EmberCard, EmberCardSection } from "components/generic/ember-card"
import Hero from "components/hero"
import { DriveLayout } from "components/layout-custom"

const Page = () => (
  <DriveLayout title="Alcolock Failure">
    <Hero title="Alcolock Failure Process" />
    <Container size="medium">
      <Stack spacing="XLarge">
        <EmberCard>
          <EmberCardSection noSeparator>
            <Stack>
              <Text>
                The steps in this flowchart should be followed if you return a
                failed alcolock test when attempting to start a vehicle.
              </Text>
              <StaticImage
                src="./images/alcolock-flowchart.jpg"
                alt="Alcolock Flowchart"
                width={800}
              />
            </Stack>
          </EmberCardSection>
        </EmberCard>
      </Stack>
    </Container>
  </DriveLayout>
)

export default Page
